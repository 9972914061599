import { dew as _GrabberDew } from "./Grabber";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadExternalGrabInteraction = void 0;

  const Grabber_1 = _GrabberDew();

  async function loadExternalGrabInteraction(tsParticles) {
    await tsParticles.addInteractor("externalGrab", container => new Grabber_1.Grabber(container));
  }

  exports.loadExternalGrabInteraction = loadExternalGrabInteraction;
  return exports;
}