import { dew as _ValueWithRandomDew } from "../../../../Options/Classes/ValueWithRandom";
import { dew as _AbsorberSizeLimitDew } from "./AbsorberSizeLimit";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.AbsorberSize = void 0;

  const ValueWithRandom_1 = _ValueWithRandomDew();

  const AbsorberSizeLimit_1 = _AbsorberSizeLimitDew();

  class AbsorberSize extends ValueWithRandom_1.ValueWithRandom {
    constructor() {
      super();
      this.density = 5;
      this.random.minimumValue = 1;
      this.value = 50;
      this.limit = new AbsorberSizeLimit_1.AbsorberSizeLimit();
    }

    load(data) {
      if (!data) {
        return;
      }

      super.load(data);

      if (data.density !== undefined) {
        this.density = data.density;
      }

      if (typeof data.limit === "number") {
        this.limit.radius = data.limit;
      } else {
        this.limit.load(data.limit);
      }
    }

  }

  exports.AbsorberSize = AbsorberSize;
  return exports;
}