import { dew as _UtilsDew } from "../../Utils";
import { dew as _EmittersDew } from "./Emitters";
import { dew as _EmitterDew } from "./Options/Classes/Emitter";
import { dew as _ShapeManagerDew } from "./ShapeManager";
import { dew as _CircleShapeDew } from "./Shapes/Circle/CircleShape";
import { dew as _SquareShapeDew } from "./Shapes/Square/SquareShape";
import { dew as _EmittersMainDew } from "./EmittersMain";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });

  var __exportStar = exports && exports.__exportStar || function (m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadEmittersPlugin = void 0;

  const Utils_1 = _UtilsDew();

  const Emitters_1 = _EmittersDew();

  const Emitter_1 = _EmitterDew();

  const ShapeManager_1 = _ShapeManagerDew();

  const CircleShape_1 = _CircleShapeDew();

  const SquareShape_1 = _SquareShapeDew();

  class EmittersPlugin {
    constructor() {
      this.id = "emitters";
    }

    getPlugin(container) {
      return new Emitters_1.Emitters(container);
    }

    needsPlugin(options) {
      var _a, _b, _c;

      if (options === undefined) {
        return false;
      }

      const emitters = options.emitters;
      return emitters instanceof Array && !!emitters.length || emitters !== undefined || !!((_c = (_b = (_a = options.interactivity) === null || _a === void 0 ? void 0 : _a.events) === null || _b === void 0 ? void 0 : _b.onClick) === null || _c === void 0 ? void 0 : _c.mode) && (0, Utils_1.isInArray)("emitter", options.interactivity.events.onClick.mode);
    }

    loadOptions(options, source) {
      var _a, _b;

      if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
        return;
      }

      const optionsCast = options;

      if (source === null || source === void 0 ? void 0 : source.emitters) {
        if ((source === null || source === void 0 ? void 0 : source.emitters) instanceof Array) {
          optionsCast.emitters = source === null || source === void 0 ? void 0 : source.emitters.map(s => {
            const tmp = new Emitter_1.Emitter();
            tmp.load(s);
            return tmp;
          });
        } else {
          let emitterOptions = optionsCast.emitters;

          if ((emitterOptions === null || emitterOptions === void 0 ? void 0 : emitterOptions.load) === undefined) {
            optionsCast.emitters = emitterOptions = new Emitter_1.Emitter();
          }

          emitterOptions.load(source === null || source === void 0 ? void 0 : source.emitters);
        }
      }

      const interactivityEmitters = (_b = (_a = source === null || source === void 0 ? void 0 : source.interactivity) === null || _a === void 0 ? void 0 : _a.modes) === null || _b === void 0 ? void 0 : _b.emitters;

      if (interactivityEmitters) {
        if (interactivityEmitters instanceof Array) {
          optionsCast.interactivity.modes.emitters = interactivityEmitters.map(s => {
            const tmp = new Emitter_1.Emitter();
            tmp.load(s);
            return tmp;
          });
        } else {
          let emitterOptions = optionsCast.interactivity.modes.emitters;

          if ((emitterOptions === null || emitterOptions === void 0 ? void 0 : emitterOptions.load) === undefined) {
            optionsCast.interactivity.modes.emitters = emitterOptions = new Emitter_1.Emitter();
          }

          emitterOptions.load(interactivityEmitters);
        }
      }
    }

  }

  async function loadEmittersPlugin(tsParticles) {
    const plugin = new EmittersPlugin();
    await tsParticles.addPlugin(plugin);

    if (!tsParticles.addEmitterShape) {
      tsParticles.addEmitterShape = (name, shape) => {
        ShapeManager_1.ShapeManager.addShape(name, shape);
      };
    }

    tsParticles.addEmitterShape("circle", new CircleShape_1.CircleShape());
    tsParticles.addEmitterShape("square", new SquareShape_1.SquareShape());
  }

  exports.loadEmittersPlugin = loadEmittersPlugin;

  __exportStar(_EmittersMainDew(), exports);

  return exports;
}