var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PolygonDrawerBase = void 0;

  class PolygonDrawerBase {
    getSidesCount(particle) {
      var _a, _b;

      const polygon = particle.shapeData;
      return (_b = (_a = polygon === null || polygon === void 0 ? void 0 : polygon.sides) !== null && _a !== void 0 ? _a : polygon === null || polygon === void 0 ? void 0 : polygon.nb_sides) !== null && _b !== void 0 ? _b : 5;
    }

    draw(context, particle, radius) {
      const start = this.getCenter(particle, radius);
      const side = this.getSidesData(particle, radius);
      const sideCount = side.count.numerator * side.count.denominator;
      const decimalSides = side.count.numerator / side.count.denominator;
      const interiorAngleDegrees = 180 * (decimalSides - 2) / decimalSides;
      const interiorAngle = Math.PI - Math.PI * interiorAngleDegrees / 180;

      if (!context) {
        return;
      }

      context.beginPath();
      context.translate(start.x, start.y);
      context.moveTo(0, 0);

      for (let i = 0; i < sideCount; i++) {
        context.lineTo(side.length, 0);
        context.translate(side.length, 0);
        context.rotate(interiorAngle);
      }
    }

  }

  exports.PolygonDrawerBase = PolygonDrawerBase;
  return exports;
}