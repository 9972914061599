import { dew as _PolygonDrawerBaseDew } from "./PolygonDrawerBase";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TriangleDrawer = void 0;

  const PolygonDrawerBase_1 = _PolygonDrawerBaseDew();

  class TriangleDrawer extends PolygonDrawerBase_1.PolygonDrawerBase {
    getSidesCount() {
      return 3;
    }

    getSidesData(particle, radius) {
      return {
        count: {
          denominator: 2,
          numerator: 3
        },
        length: radius * 2
      };
    }

    getCenter(particle, radius) {
      return {
        x: -radius,
        y: radius / 1.66
      };
    }

  }

  exports.TriangleDrawer = TriangleDrawer;
  return exports;
}