import { dew as _TextDrawerDew } from "./TextDrawer";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadTextShape = void 0;

  const TextDrawer_1 = _TextDrawerDew();

  async function loadTextShape(tsParticles) {
    const drawer = new TextDrawer_1.TextDrawer();

    for (const type of TextDrawer_1.validTypes) {
      await tsParticles.addShape(type, drawer);
    }
  }

  exports.loadTextShape = loadTextShape;
  return exports;
}