import { dew as _RepulserDew } from "./Repulser";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadExternalRepulseInteraction = void 0;

  const Repulser_1 = _RepulserDew();

  async function loadExternalRepulseInteraction(tsParticles) {
    await tsParticles.addInteractor("externalRepulse", container => new Repulser_1.Repulser(container));
  }

  exports.loadExternalRepulseInteraction = loadExternalRepulseInteraction;
  return exports;
}