import { dew as _AttractorDew } from "./Attractor";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadParticlesAttractInteraction = void 0;

  const Attractor_1 = _AttractorDew();

  async function loadParticlesAttractInteraction(tsParticles) {
    await tsParticles.addInteractor("particlesAttract", container => new Attractor_1.Attractor(container));
  }

  exports.loadParticlesAttractInteraction = loadParticlesAttractInteraction;
  return exports;
}