import { dew as _ConnectorDew } from "./Connector";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadExternalConnectInteraction = void 0;

  const Connector_1 = _ConnectorDew();

  async function loadExternalConnectInteraction(tsParticles) {
    await tsParticles.addInteractor("externalConnect", container => new Connector_1.Connector(container));
  }

  exports.loadExternalConnectInteraction = loadExternalConnectInteraction;
  return exports;
}