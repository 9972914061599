import { dew as _OptionsColorDew } from "../../../../Options/Classes/OptionsColor";
import { dew as _UtilsDew } from "../../../../Utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PolygonMaskDrawStroke = void 0;

  const OptionsColor_1 = _OptionsColorDew();

  const Utils_1 = _UtilsDew();

  class PolygonMaskDrawStroke {
    constructor() {
      this.color = new OptionsColor_1.OptionsColor();
      this.width = 0.5;
      this.opacity = 1;
    }

    load(data) {
      var _a;

      if (!data) {
        return;
      }

      this.color = OptionsColor_1.OptionsColor.create(this.color, data.color);

      if (typeof this.color.value === "string") {
        this.opacity = (_a = (0, Utils_1.stringToAlpha)(this.color.value)) !== null && _a !== void 0 ? _a : this.opacity;
      }

      if (data.opacity !== undefined) {
        this.opacity = data.opacity;
      }

      if (data.width !== undefined) {
        this.width = data.width;
      }
    }

  }

  exports.PolygonMaskDrawStroke = PolygonMaskDrawStroke;
  return exports;
}