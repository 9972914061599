import { dew as _UtilsDew } from "../../Utils";
import { dew as _Utils2Dew } from "./Utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.OutOfCanvasUpdater = void 0;

  const Utils_1 = _UtilsDew();

  const Utils_2 = _Utils2Dew();

  class OutOfCanvasUpdater {
    constructor(container) {
      this.container = container;
    }

    init() {}

    isEnabled(particle) {
      return !particle.destroyed && !particle.spawning;
    }

    update(particle, delta) {
      var _a, _b, _c, _d;

      const outModes = particle.options.move.outModes;
      this.updateOutMode(particle, delta, (_a = outModes.bottom) !== null && _a !== void 0 ? _a : outModes.default, "bottom");
      this.updateOutMode(particle, delta, (_b = outModes.left) !== null && _b !== void 0 ? _b : outModes.default, "left");
      this.updateOutMode(particle, delta, (_c = outModes.right) !== null && _c !== void 0 ? _c : outModes.default, "right");
      this.updateOutMode(particle, delta, (_d = outModes.top) !== null && _d !== void 0 ? _d : outModes.default, "top");
    }

    updateOutMode(particle, delta, outMode, direction) {
      switch (outMode) {
        case "bounce":
        case "bounce-vertical":
        case "bounce-horizontal":
        case "bounceVertical":
        case "bounceHorizontal":
        case "split":
          this.bounce(particle, delta, direction, outMode);
          break;

        case "destroy":
          this.destroy(particle, direction);
          break;

        case "out":
          this.out(particle, direction);
          break;

        case "none":
        default:
          this.none(particle, direction);
          break;
      }
    }

    destroy(particle, direction) {
      const container = this.container;

      if ((0, Utils_1.isPointInside)(particle.position, container.canvas.size, particle.getRadius(), direction)) {
        return;
      }

      container.particles.remove(particle, undefined, true);
    }

    out(particle, direction) {
      const container = this.container;

      if ((0, Utils_1.isPointInside)(particle.position, container.canvas.size, particle.getRadius(), direction)) {
        return;
      }

      const wrap = particle.options.move.warp,
            canvasSize = container.canvas.size,
            newPos = {
        bottom: canvasSize.height + particle.getRadius() + particle.offset.y,
        left: -particle.getRadius() - particle.offset.x,
        right: canvasSize.width + particle.getRadius() + particle.offset.x,
        top: -particle.getRadius() - particle.offset.y
      },
            sizeValue = particle.getRadius(),
            nextBounds = (0, Utils_1.calculateBounds)(particle.position, sizeValue);

      if (direction === "right" && nextBounds.left > canvasSize.width + particle.offset.x) {
        particle.position.x = newPos.left;
        particle.initialPosition.x = particle.position.x;

        if (!wrap) {
          particle.position.y = Math.random() * canvasSize.height;
          particle.initialPosition.y = particle.position.y;
        }
      } else if (direction === "left" && nextBounds.right < -particle.offset.x) {
        particle.position.x = newPos.right;
        particle.initialPosition.x = particle.position.x;

        if (!wrap) {
          particle.position.y = Math.random() * canvasSize.height;
          particle.initialPosition.y = particle.position.y;
        }
      }

      if (direction === "bottom" && nextBounds.top > canvasSize.height + particle.offset.y) {
        if (!wrap) {
          particle.position.x = Math.random() * canvasSize.width;
          particle.initialPosition.x = particle.position.x;
        }

        particle.position.y = newPos.top;
        particle.initialPosition.y = particle.position.y;
      } else if (direction === "top" && nextBounds.bottom < -particle.offset.y) {
        if (!wrap) {
          particle.position.x = Math.random() * canvasSize.width;
          particle.initialPosition.x = particle.position.x;
        }

        particle.position.y = newPos.bottom;
        particle.initialPosition.y = particle.position.y;
      }
    }

    bounce(particle, delta, direction, outMode) {
      const container = this.container;
      let handled = false;

      for (const [, plugin] of container.plugins) {
        if (plugin.particleBounce !== undefined) {
          handled = plugin.particleBounce(particle, delta, direction);
        }

        if (handled) {
          break;
        }
      }

      if (handled) {
        return;
      }

      const pos = particle.getPosition(),
            offset = particle.offset,
            size = particle.getRadius(),
            bounds = (0, Utils_1.calculateBounds)(pos, size),
            canvasSize = container.canvas.size;
      (0, Utils_2.bounceHorizontal)({
        particle,
        outMode,
        direction,
        bounds,
        canvasSize,
        offset,
        size
      });
      (0, Utils_2.bounceVertical)({
        particle,
        outMode,
        direction,
        bounds,
        canvasSize,
        offset,
        size
      });
    }

    none(particle, direction) {
      if (particle.options.move.distance.horizontal && (direction === "left" || direction === "right") || particle.options.move.distance.vertical && (direction === "top" || direction === "bottom")) {
        return;
      }

      const gravityOptions = particle.options.move.gravity,
            container = this.container;
      const canvasSize = container.canvas.size;
      const pRadius = particle.getRadius();

      if (!gravityOptions.enable) {
        if (particle.velocity.y > 0 && particle.position.y <= canvasSize.height + pRadius || particle.velocity.y < 0 && particle.position.y >= -pRadius || particle.velocity.x > 0 && particle.position.x <= canvasSize.width + pRadius || particle.velocity.x < 0 && particle.position.x >= -pRadius) {
          return;
        }

        if (!(0, Utils_1.isPointInside)(particle.position, container.canvas.size, pRadius, direction)) {
          container.particles.remove(particle);
        }
      } else {
        const position = particle.position;

        if (!gravityOptions.inverse && position.y > canvasSize.height + pRadius && direction === "bottom" || gravityOptions.inverse && position.y < -pRadius && direction === "top") {
          container.particles.remove(particle);
        }
      }
    }

  }

  exports.OutOfCanvasUpdater = OutOfCanvasUpdater;
  return exports;
}