import { dew as _EmitterRateDew } from "./EmitterRate";
import { dew as _EmitterLifeDew } from "./EmitterLife";
import { dew as _UtilsDew } from "../../../../Utils";
import { dew as _EmitterSizeDew } from "./EmitterSize";
import { dew as _AnimatableColorDew } from "../../../../Options/Classes/AnimatableColor";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Emitter = void 0;

  const EmitterRate_1 = _EmitterRateDew();

  const EmitterLife_1 = _EmitterLifeDew();

  const Utils_1 = _UtilsDew();

  const EmitterSize_1 = _EmitterSizeDew();

  const AnimatableColor_1 = _AnimatableColorDew();

  class Emitter {
    constructor() {
      this.autoPlay = true;
      this.fill = true;
      this.life = new EmitterLife_1.EmitterLife();
      this.rate = new EmitterRate_1.EmitterRate();
      this.shape = "square";
      this.startCount = 0;
    }

    load(data) {
      if (data === undefined) {
        return;
      }

      if (data.autoPlay !== undefined) {
        this.autoPlay = data.autoPlay;
      }

      if (data.size !== undefined) {
        if (this.size === undefined) {
          this.size = new EmitterSize_1.EmitterSize();
        }

        this.size.load(data.size);
      }

      if (data.direction !== undefined) {
        this.direction = data.direction;
      }

      if (data.fill !== undefined) {
        this.fill = data.fill;
      }

      this.life.load(data.life);
      this.name = data.name;

      if (data.particles !== undefined) {
        this.particles = (0, Utils_1.deepExtend)({}, data.particles);
      }

      this.rate.load(data.rate);

      if (data.shape !== undefined) {
        this.shape = data.shape;
      }

      if (data.position !== undefined) {
        this.position = {
          x: data.position.x,
          y: data.position.y
        };
      }

      if (data.spawnColor !== undefined) {
        if (this.spawnColor === undefined) {
          this.spawnColor = new AnimatableColor_1.AnimatableColor();
        }

        this.spawnColor.load(data.spawnColor);
      }

      if (data.startCount !== undefined) {
        this.startCount = data.startCount;
      }
    }

  }

  exports.Emitter = Emitter;
  return exports;
}