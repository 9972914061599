import { dew as _CircleDrawerDew } from "./CircleDrawer";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadCircleShape = void 0;

  const CircleDrawer_1 = _CircleDrawerDew();

  async function loadCircleShape(tsParticles) {
    await tsParticles.addShape("circle", new CircleDrawer_1.CircleDrawer());
  }

  exports.loadCircleShape = loadCircleShape;
  return exports;
}