import { dew as _UtilsDew } from "../../../../Utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EmitterRate = void 0;

  const Utils_1 = _UtilsDew();

  class EmitterRate {
    constructor() {
      this.quantity = 1;
      this.delay = 0.1;
    }

    load(data) {
      if (data === undefined) {
        return;
      }

      if (data.quantity !== undefined) {
        this.quantity = (0, Utils_1.setRangeValue)(data.quantity);
      }

      if (data.delay !== undefined) {
        this.delay = (0, Utils_1.setRangeValue)(data.delay);
      }
    }

  }

  exports.EmitterRate = EmitterRate;
  return exports;
}