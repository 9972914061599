var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.LineDrawer = void 0;

  class LineDrawer {
    getSidesCount() {
      return 1;
    }

    draw(context, particle, radius) {
      context.moveTo(-radius / 2, 0);
      context.lineTo(radius / 2, 0);
    }

  }

  exports.LineDrawer = LineDrawer;
  return exports;
}