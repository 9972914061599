var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EmitterSize = void 0;

  class EmitterSize {
    constructor() {
      this.mode = "percent";
      this.height = 0;
      this.width = 0;
    }

    load(data) {
      if (data === undefined) {
        return;
      }

      if (data.mode !== undefined) {
        this.mode = data.mode;
      }

      if (data.height !== undefined) {
        this.height = data.height;
      }

      if (data.width !== undefined) {
        this.width = data.width;
      }
    }

  }

  exports.EmitterSize = EmitterSize;
  return exports;
}