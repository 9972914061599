import { dew as _RangeValueDew } from "./RangeValue";
import { dew as _RecursivePartialDew } from "./RecursivePartial";
import { dew as _ShapeDataDew } from "./ShapeData";
import { dew as _ShapeDrawerFunctionsDew } from "./ShapeDrawerFunctions";
import { dew as _SingleOrMultipleDew } from "./SingleOrMultiple";
import { dew as _PathOptionsDew } from "./PathOptions";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });

  var __exportStar = exports && exports.__exportStar || function (m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  __exportStar(_RangeValueDew(), exports);

  __exportStar(_RecursivePartialDew(), exports);

  __exportStar(_ShapeDataDew(), exports);

  __exportStar(_ShapeDrawerFunctionsDew(), exports);

  __exportStar(_SingleOrMultipleDew(), exports);

  __exportStar(_PathOptionsDew(), exports);

  return exports;
}