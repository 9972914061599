import { dew as _AbsorbersDew } from "./Absorbers";
import { dew as _AbsorberDew } from "./Options/Classes/Absorber";
import { dew as _UtilsDew } from "../../Utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadAbsorbersPlugin = void 0;

  const Absorbers_1 = _AbsorbersDew();

  const Absorber_1 = _AbsorberDew();

  const Utils_1 = _UtilsDew();

  class AbsorbersPlugin {
    constructor() {
      this.id = "absorbers";
    }

    getPlugin(container) {
      return new Absorbers_1.Absorbers(container);
    }

    needsPlugin(options) {
      var _a, _b, _c;

      if (options === undefined) {
        return false;
      }

      const absorbers = options.absorbers;
      let loadAbsorbers = false;

      if (absorbers instanceof Array) {
        if (absorbers.length) {
          loadAbsorbers = true;
        }
      } else if (absorbers !== undefined) {
        loadAbsorbers = true;
      } else if (((_c = (_b = (_a = options.interactivity) === null || _a === void 0 ? void 0 : _a.events) === null || _b === void 0 ? void 0 : _b.onClick) === null || _c === void 0 ? void 0 : _c.mode) && (0, Utils_1.isInArray)("absorber", options.interactivity.events.onClick.mode)) {
        loadAbsorbers = true;
      }

      return loadAbsorbers;
    }

    loadOptions(options, source) {
      var _a, _b;

      if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
        return;
      }

      const optionsCast = options;

      if (source === null || source === void 0 ? void 0 : source.absorbers) {
        if ((source === null || source === void 0 ? void 0 : source.absorbers) instanceof Array) {
          optionsCast.absorbers = source === null || source === void 0 ? void 0 : source.absorbers.map(s => {
            const tmp = new Absorber_1.Absorber();
            tmp.load(s);
            return tmp;
          });
        } else {
          let absorberOptions = optionsCast.absorbers;

          if ((absorberOptions === null || absorberOptions === void 0 ? void 0 : absorberOptions.load) === undefined) {
            optionsCast.absorbers = absorberOptions = new Absorber_1.Absorber();
          }

          absorberOptions.load(source === null || source === void 0 ? void 0 : source.absorbers);
        }
      }

      const interactivityAbsorbers = (_b = (_a = source === null || source === void 0 ? void 0 : source.interactivity) === null || _a === void 0 ? void 0 : _a.modes) === null || _b === void 0 ? void 0 : _b.absorbers;

      if (interactivityAbsorbers) {
        if (interactivityAbsorbers instanceof Array) {
          optionsCast.interactivity.modes.absorbers = interactivityAbsorbers.map(s => {
            const tmp = new Absorber_1.Absorber();
            tmp.load(s);
            return tmp;
          });
        } else {
          let absorberOptions = optionsCast.interactivity.modes.absorbers;

          if ((absorberOptions === null || absorberOptions === void 0 ? void 0 : absorberOptions.load) === undefined) {
            optionsCast.interactivity.modes.absorbers = absorberOptions = new Absorber_1.Absorber();
          }

          absorberOptions.load(interactivityAbsorbers);
        }
      }
    }

  }

  async function loadAbsorbersPlugin(tsParticles) {
    const plugin = new AbsorbersPlugin();
    await tsParticles.addPlugin(plugin);
  }

  exports.loadAbsorbersPlugin = loadAbsorbersPlugin;
  return exports;
}