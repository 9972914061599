import { dew as _slimDew } from "./slim";
import { dew as _TrailDew } from "./Interactions/External/Trail";
import { dew as _TiltDew } from "./Updaters/Tilt";
import { dew as _WobbleDew } from "./Updaters/Wobble";
import { dew as _RollDew } from "./Updaters/Roll";
import { dew as _AbsorbersDew } from "./Plugins/Absorbers";
import { dew as _EmittersDew } from "./Plugins/Emitters";
import { dew as _PolygonMaskDew } from "./Plugins/PolygonMask";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadFull = void 0;

  const slim_1 = _slimDew();

  const Trail_1 = _TrailDew();

  const Tilt_1 = _TiltDew();

  const Wobble_1 = _WobbleDew();

  const Roll_1 = _RollDew();

  const Absorbers_1 = _AbsorbersDew();

  const Emitters_1 = _EmittersDew();

  const PolygonMask_1 = _PolygonMaskDew();

  async function loadFull(tsParticles) {
    await (0, slim_1.loadSlim)(tsParticles);
    await (0, Trail_1.loadExternalTrailInteraction)(tsParticles);
    await (0, Roll_1.loadRollUpdater)(tsParticles);
    await (0, Tilt_1.loadTiltUpdater)(tsParticles);
    await (0, Wobble_1.loadWobbleUpdater)(tsParticles);
    await (0, Absorbers_1.loadAbsorbersPlugin)(tsParticles);
    await (0, Emitters_1.loadEmittersPlugin)(tsParticles);
    await (0, PolygonMask_1.loadPolygonMaskPlugin)(tsParticles);
  }

  exports.loadFull = loadFull;
  return exports;
}