import { dew as _BubblerDew } from "./Bubbler";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadExternalBubbleInteraction = void 0;

  const Bubbler_1 = _BubblerDew();

  async function loadExternalBubbleInteraction(tsParticles) {
    await tsParticles.addInteractor("externalBubble", container => new Bubbler_1.Bubbler(container));
  }

  exports.loadExternalBubbleInteraction = loadExternalBubbleInteraction;
  return exports;
}