var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.CircleShape = void 0;

  class CircleShape {
    randomPosition(position, offset, fill) {
      const generateTheta = (x, y) => {
        const u = Math.random() / 4;
        const theta = Math.atan(y / x * Math.tan(2 * Math.PI * u));
        const v = Math.random();

        if (v < 0.25) {
          return theta;
        } else if (v < 0.5) {
          return Math.PI - theta;
        } else if (v < 0.75) {
          return Math.PI + theta;
        } else {
          return -theta;
        }
      };

      const radius = (x, y, theta) => x * y / Math.sqrt((y * Math.cos(theta)) ** 2 + (x * Math.sin(theta)) ** 2);

      const [a, b] = [offset.x / 2, offset.y / 2];
      const randomTheta = generateTheta(a, b),
            maxRadius = radius(a, b, randomTheta),
            randomRadius = fill ? maxRadius * Math.sqrt(Math.random()) : maxRadius;
      return {
        x: position.x + randomRadius * Math.cos(randomTheta),
        y: position.y + randomRadius * Math.sin(randomTheta)
      };
    }

  }

  exports.CircleShape = CircleShape;
  return exports;
}