import { dew as _AbsorberInstanceDew } from "./AbsorberInstance";
import { dew as _AbsorberDew } from "./Options/Classes/Absorber";
import { dew as _UtilsDew } from "../../Utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Absorbers = void 0;

  const AbsorberInstance_1 = _AbsorberInstanceDew();

  const Absorber_1 = _AbsorberDew();

  const Utils_1 = _UtilsDew();

  class Absorbers {
    constructor(container) {
      this.container = container;
      this.array = [];
      this.absorbers = [];
      this.interactivityAbsorbers = [];
      const overridableContainer = container;

      overridableContainer.getAbsorber = idxOrName => idxOrName === undefined || typeof idxOrName === "number" ? this.array[idxOrName || 0] : this.array.find(t => t.name === idxOrName);

      overridableContainer.addAbsorber = (options, position) => this.addAbsorber(options, position);
    }

    init(options) {
      var _a, _b;

      if (!options) {
        return;
      }

      if (options.absorbers) {
        if (options.absorbers instanceof Array) {
          this.absorbers = options.absorbers.map(s => {
            const tmp = new Absorber_1.Absorber();
            tmp.load(s);
            return tmp;
          });
        } else {
          if (this.absorbers instanceof Array) {
            this.absorbers = new Absorber_1.Absorber();
          }

          this.absorbers.load(options.absorbers);
        }
      }

      const interactivityAbsorbers = (_b = (_a = options.interactivity) === null || _a === void 0 ? void 0 : _a.modes) === null || _b === void 0 ? void 0 : _b.absorbers;

      if (interactivityAbsorbers) {
        if (interactivityAbsorbers instanceof Array) {
          this.interactivityAbsorbers = interactivityAbsorbers.map(s => {
            const tmp = new Absorber_1.Absorber();
            tmp.load(s);
            return tmp;
          });
        } else {
          if (this.interactivityAbsorbers instanceof Array) {
            this.interactivityAbsorbers = new Absorber_1.Absorber();
          }

          this.interactivityAbsorbers.load(interactivityAbsorbers);
        }
      }

      if (this.absorbers instanceof Array) {
        for (const absorberOptions of this.absorbers) {
          this.addAbsorber(absorberOptions);
        }
      } else {
        this.addAbsorber(this.absorbers);
      }
    }

    particleUpdate(particle) {
      for (const absorber of this.array) {
        absorber.attract(particle);

        if (particle.destroyed) {
          break;
        }
      }
    }

    draw(context) {
      for (const absorber of this.array) {
        context.save();
        absorber.draw(context);
        context.restore();
      }
    }

    stop() {
      this.array = [];
    }

    resize() {
      for (const absorber of this.array) {
        absorber.resize();
      }
    }

    handleClickMode(mode) {
      const container = this.container;
      const absorberOptions = this.absorbers;
      const modeAbsorbers = this.interactivityAbsorbers;

      if (mode === "absorber") {
        let absorbersModeOptions;

        if (modeAbsorbers instanceof Array) {
          if (modeAbsorbers.length > 0) {
            absorbersModeOptions = (0, Utils_1.itemFromArray)(modeAbsorbers);
          }
        } else {
          absorbersModeOptions = modeAbsorbers;
        }

        const absorbersOptions = absorbersModeOptions !== null && absorbersModeOptions !== void 0 ? absorbersModeOptions : absorberOptions instanceof Array ? (0, Utils_1.itemFromArray)(absorberOptions) : absorberOptions;
        const aPosition = container.interactivity.mouse.clickPosition;
        this.addAbsorber(absorbersOptions, aPosition);
      }
    }

    addAbsorber(options, position) {
      const absorber = new AbsorberInstance_1.AbsorberInstance(this, this.container, options, position);
      this.array.push(absorber);
      return absorber;
    }

    removeAbsorber(absorber) {
      const index = this.array.indexOf(absorber);

      if (index >= 0) {
        this.array.splice(index, 1);
      }
    }

  }

  exports.Absorbers = Absorbers;
  return exports;
}