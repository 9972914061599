import { dew as _TiltUpdaterDew } from "./TiltUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadTiltUpdater = void 0;

  const TiltUpdater_1 = _TiltUpdaterDew();

  async function loadTiltUpdater(tsParticles) {
    await tsParticles.addParticleUpdater("tilt", container => new TiltUpdater_1.TiltUpdater(container));
  }

  exports.loadTiltUpdater = loadTiltUpdater;
  return exports;
}