import { dew as _OpacityUpdaterDew } from "./OpacityUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadOpacityUpdater = void 0;

  const OpacityUpdater_1 = _OpacityUpdaterDew();

  async function loadOpacityUpdater(tsParticles) {
    await tsParticles.addParticleUpdater("opacity", container => new OpacityUpdater_1.OpacityUpdater(container));
  }

  exports.loadOpacityUpdater = loadOpacityUpdater;
  return exports;
}