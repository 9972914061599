import { dew as _RollUpdaterDew } from "./RollUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadRollUpdater = void 0;

  const RollUpdater_1 = _RollUpdaterDew();

  async function loadRollUpdater(tsParticles) {
    await tsParticles.addParticleUpdater("roll", () => new RollUpdater_1.RollUpdater());
  }

  exports.loadRollUpdater = loadRollUpdater;
  return exports;
}